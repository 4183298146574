body {
  margin: 0;
  outline: 0;

  height: 100vh !important;

  /* precisa colocar o overflow interno pra controlar isso internamente e não no body */
  overflow: hidden;

  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #384252;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #384252;
}

body * {
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}